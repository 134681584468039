/* Container */
.patient-grid-container {
  width: 100%;
  color: var(--text);
  display: grid;
  grid-template-columns: 250px calc(100% - 250px);
}

/* second column in the patient grid */
.patient-second-column {
  padding: 20px;
  margin-top: 100px;
}

/* patient information */
.patient-info-container {
  display: flex;
}

/*  patient picture */
.patient-picture {
  height: 5rem;
  padding: 1rem;
}

/*  patient name and medical information */
.patient-name-medical h1 {
  margin-top: 15px;
}

/* patient's phone and date of birth */
.patient-phone-dob-container {
  display: flex;
}

/*  individual patient phone and date of birth sections */
.patient-phone-dob {
  padding: 0 10px;
  text-align: center;
}

/* patient phone and date of birth sections */
.patient-phone-dob p {
  font-weight: bold;
}

/* Container  */
.position-container {
  display: flex;
  justify-content: space-between;
  padding: 2px 20px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
  margin-bottom: 20px;
}

/* surgery type section */
.surgery-type {
  display: flex;
  align-items: center;
}

/*  surgery card */
.surgery-card {
  margin-top: 10px;
}

/* surgery button */
.surgery-btn {
  background-color: var(--btn-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 20px;
  align-self: center;
}

/* surgery button*/
.surgery-btn-container {
  display: flex;
  justify-content: flex-end;
}

.hover-pointer:hover {
  cursor: pointer;
}
