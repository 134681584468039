/* form container */
.nurse-ques-form {
  position: relative;
  z-index: 2;
  background: var(--blue-bg);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  width: 100%;
  box-sizing: border-box;
}

/* Container for form content */
.nurse-ques-container {
  margin: 20px;
  padding: 16px;
}

/* Row layout for form */
.nurse-ques-form-row {
  display: flex;
}

/*  BMI group  */
.bmi-group {
  flex: 1;
  margin-bottom: 8px;
}

/* form groups with column direction */
.nurse-ques-form-group {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.nurse-ques-form label,
.question-section h3 {
  color: #000;
  font-weight: bold;
}
/* Group for form inputs\ */
.nurse-ques-input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

/* Input field container\ */
.nurse-ques-field {
  display: flex;
  flex-direction: column;
}

/* label below inputs */
.below-label {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

/* Toggle switch container */
.switch-group {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 20px;
}

/* form labels */
.nurse-ques-form-group label {
  font-weight: bold;
  margin-bottom: 8px;
}
/* surgery input */
.surgery-name {
  width: 100%;
}
/* billing input */
#billing {
  width: 25%;
}
/* inputs and selects */
.nurse-ques-form-group input,
.nurse-ques-form-group select,
.medications-table input,
.insurance-table input,
.surgeries-table input,
.full-width-input,
.question-row input {
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
  width: 100%;
}

/* Full-width input  */
.full-width-input {
  margin-top: 8px;
}

/* input groups */
.nurse-ques-input-group {
  display: flex;
}

/* inputs within input groups */
.nurse-ques-nput-group input {
  flex: 1;
  margin-right: 10px;
}

/* last input in the input group */
.nurse-ques-input-group input:last-child {
  margin-right: 0;
}

/* labels within switch groups */
.switch-group label {
  margin-right: 10px;
}

/* Diagnosis input group  */
.diagnosis-group {
  flex: 1 100%;
}

/* small span elements */
span {
  font-size: 12px;
  color: #888;
}

/* Hidden checkbox (used for toggles) */
.hidden-checkbox {
  display: none;
}

/* ToggleSwitch.css */
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slider-checkbox {
  display: none;
}

.slider-toggle {
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 12px;
  position: relative;
  transition: background-color 0.3s;
}

.slider-toggle.active {
  background-color: #598dde;
}

.slider-toggle::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

.slider-toggle.active::before {
  transform: translateX(26px);
}

.toggle-label {
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}
/* Section for questions */
.question-section {
  margin-bottom: 20px;
}

/* Grid layout for question rows  */
.question-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* individual question rows */
.question-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

/* Flex layout for question container */
.question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* labels within question rows */
.question-row label {
  flex: 1;
  font-weight: bold;
}

/*  dropdowns in question rows */
.question-row select {
  margin-top: 5px;
  padding: 5px;
  width: auto;
}

/* Input field container */
.input-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Dropdown container*/
.dropdown-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

/* space between dropdown and input */
.dropdown-container select {
  margin-bottom: 5px;
  padding: 5px;
  width: auto;
}

/* inline inputs under dropdowns */
.inline-input {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Label within question container  */
.question-container label {
  flex-grow: 1;
  margin-right: 20px;
  font-weight: bold;
}

/* dropdown select inside question container */
.question-container select {
  width: 80px;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
}

/* Container for bottom questionnaire */
.bottom-questionnaire-container {
  width: 100%;
  max-width: 600px;
  padding-top: 20px;
}

/* Section for bottom questions */
.bottom-question-section {
  margin-bottom: 20px;
}

/* bottom questions with column direction */
.bottom-question-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

/*  bottom question row */
.bottom-question-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

/* Container for bottom questions*/
.bottom-question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.bottom-question-container label {
  flex: 1;
}

.bottom-question-container select {
  width: auto;
}

.bottom-full-width-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  box-sizing: border-box;
}
/* Select inputs styling */
select,
.pregnant {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
}

/* Date input and checkbox  */
.bottom-input-group {
  display: flex;
  align-items: center;
}

/* bottom date input */
.bottom-date-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

/*  "N/A" group */
.n-a-checkbox {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

/* "N/A" group */
.n-a-checkbox input {
  margin-right: 5px;
}

/* Full width input field */
.bottom-full-width-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  box-sizing: border-box;
  margin-top: 8px;
}
.travel {
  margin-top: 20px;
  display: flex;
  width: 50%;
  justify-content: space-between;
}
.ques-submit-btn {
  background-color: #f3f3f3;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 20px;
  align-self: center;
}
.ques-submit-btn-container {
  display: flex;
  justify-content: flex-end;
}
.nq-nav {
  margin: 8.5em 0em 0em 0em;
  background: var(--blue-bg);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 10px;
}
/* radio button styles */
.radio-inline {
  display: inline-flex;
  align-items: center;
}

.radio-inline label {
  margin: 0 10px 0 0;
  display: inline-flex;
  align-items: center;
}

.radio-inline input[type="radio"] {
  margin-left: 5px;
}

/* 
Media queries */
@media only screen and (max-width: 825px) {
  .prior-surgeries {
    margin-top: 20px;
  }
  .travel {
    width: 100%;
  }
  .nurse-ques-form {
    padding: 10px;
    /* margin-top: 30%; */
  }
  .nurse-ques-form-row,
  .nurse-ques-input-group {
    flex-direction: column;
  }

  .nurse-ques-container {
    padding: 0;
  }
  .nurse-ques-field input {
    width: 100%;
  }
  /* Label alignment  */
  .below-label {
    text-align: left;
    margin-top: 0;
  }
  /*  switch group in */
  .switch-group {
    justify-content: center;
  }
  /* Select input full width adjustment*/
  .nurse-ques-form-group select {
    width: 100%;
  }
  /* question grid*/
  .question-grid {
    display: flex;
    flex-direction: column;
  }
  /*heading sizes  */
  .nurse-ques-container h1 {
    font-size: 22px;
  }
  /* Border width adjustments */
  .nurse-ques-form > div > div.border-bottom {
    width: 66%;
  }
  /* form elements */
  .nurse-ques-form-group label,
  .nurse-ques-form-group {
    margin-bottom: 8px;
  }

  /*input elements */
  .mobile-inputs input {
    padding: 10px;
    border: 2px solid var(--input-border);
    border-radius: 4px;
    box-sizing: border-box;
    background-color: var(--input-bg);
  }
  .mobile-inputs label {
    font-weight: bold;
  }
  /*  mobile headings */
  .mobile-heading h1,
  .question-section h2 {
    font-size: 20px;
  }
  /*  mobile checkbox */
  #root
    > div.nurse-ques-form
    > div
    > div.bottom-questionnaire-container
    > div
    > div
    > div:nth-child(3)
    > div
    > div.n-a-checkbox {
    padding-right: 5px;
  }
  .mobile-border {
    margin-bottom: 20px;
  }
  #root > div.patient-info-form > div:nth-child(4) > div {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 825px) {
  .nurse-ques-form {
    width: auto;
    /* margin: 8em 1em 0em 17em; */
  }
}

@media screen and (min-width: 475px) and (max-width: 550px) {
  .nurse-ques-form {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 769px) {
  .nurse-ques-form {
    width: auto;
    margin: 8em 1em 0em 0em;
  }
}

@media screen and (min-width: 825px) and (max-width: 1440px) {
  .nurse-ques-form {
    width: auto;
    margin: 0;
  }
  .nurse-ques-form-row input {
    width: 100%;
  }
  .switch-group label,
  .nurse-ques-form-group label {
    white-space: nowrap;
  }
  .nurse-ques-container label {
    margin-bottom: 8px;
  }
  .nurse-ques-container {
    padding-right: 0;
  }
  .table-container {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .medications-table {
    font-size: 14px;
    width: 100%;
  }

  .medications-table th,
  .medications-table td {
    padding: 6px;
    line-height: 2rem;
  }

  .medications-table input,
  .medications-table select {
    width: 95%;
    padding: 6px;
    font-size: 12px;
  }

  .medication-edit-btn,
  .medication-delete-btn {
    width: 20px;
    height: 20px;
    padding: 0;
  }
}
.med-history-add-btn {
  background-color: #598dde;
  border: 1px solid rgba(128, 128, 128, 0.337);
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
  padding: 6px 12px;
}
.med-history-add-btn:hover {
  cursor: pointer;
}
.err-message {
  color: rgb(135, 6, 6);
  font-size: 12px;
}
.table-container {
  overflow: auto;
}
