.paperwork-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.paperwork-container h1 {
  margin: 0;
}
