/* Wrapper to center pin-container */
.pin-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px); 
  padding-top: 1rem; 
}

/* Center the PIN container */
.pin-container {
  background-color: #fbfdff;
  padding: 4rem;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: var(--box-shadow);
  position: relative;
  color: var(--text);
  border: 1px solid #d3ddef;
}

/* Close button in the top-right corner */
.pin-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text);
}

/* Heading */
.pin-container h2 {
  font-size: 1.25rem;
  color: var(--text);
  margin-bottom: 1rem;
}

/* PIN input fields */
.pin-inputs {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 8px;
}

.pin-inputs input {
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.25rem;
  border: none;
  border-bottom: 5px solid #ddd;
  background-color: transparent;
  outline: none;
  color: var(--text);
}

/* Continue button */
.continue-btn {
  padding: 10px 40px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #5895ee;
  color: #ffffff;
  cursor: pointer;
  margin-top: 3rem;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
@media (min-width: 350px) and (max-width: 475px) {
  .pin-container {
    padding: 2rem;
    margin:1rem;
  }
}
