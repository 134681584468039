.signup-success-page {
  margin-top: 200px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .signup-success-page {
    margin-top: 150px;
    padding: 40px;
  }
}
.signup-success-page h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--heading);
}
.signup-success-page p {
  color: var(--input-text);
}
.signup-success-page button {
  width: 200px;
  padding: 10px;
  background: var(--btn-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
}
