.otp-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
}
.otp-box {
  position: relative;
  z-index: 2;
  background: var(--blue-bg);
  padding: 60px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 400px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

.otp-validation-page input {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
  margin-bottom: 20px;
}
.reset-btn{
  width: 100%;
  padding: 10px;
  background: var(--btn-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
}
/* @media only screen and (max-width: 1000px) {
    .otp-validation-page {
      width: 50%;
    }
  } */
/* @media only screen and (max-width: 800px) {
    .otp-validation-page {
      width: 80%;
    }
  }
  .otp-validation-page input {
    width: 95%;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .reset-btn {
    background-color: var(--light-blue);
    color: var(--dark-blue-color);
    border: 2px solid var(--dark-blue-color);
    padding: 7px 28px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 25px;
    font-weight: bold;
    font-size: 20px;
    width: 225px;
    white-space: nowrap;
  } */
