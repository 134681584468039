.menu-items-patient {
  background-color: var(--blue-bg);
  border-top: 1px solid #83829259;
  padding: 15px;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px;
  width: auto;
  height: 45px;
  display: flex;
}

/*  patient tabs */
.patient-tabs {
  position: relative;
  text-decoration: none;
  color: var(--darker-blue);
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

/* Hover and focus  patient tabs */
.patient-tabs:hover,
.patient-tabs:focus {
  background-color: var(--light-blue-bg);
}

/* Active state  patient tabs */
.patient-tabs.active {
  color: var(--darker-blue);
  font-weight: bold;
}

/*  dot under the active tab */
.patient-tabs::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: transparent;
}

/* Active state  dot under the active tab */
.patient-tabs.active::after {
  background-color: var(--darker-blue);
}

@media only screen and (max-width: 768px) {
  .patient-grid-container {
    display: flex;
  }
  div.patient-heading {
    padding-left: 10px;
  }
  .patient-phone-dob-container {
    display: flex;
    justify-content: center;
  }

  div.position-container {
    flex-direction: column;
  }
  div.surgery-card {
    width: 100%;
  }
  .surgery-type {
    justify-content: center;
  }
  .surgery-date-time {
    display: flex;
    justify-content: space-around;
  }
  .surgery-btn-container {
    justify-content: center;
  }
  .patient-second-column {
    width: 100%;
    padding: 10px;
  }
  .menu-items-patient {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    font-size: 13px;
  }
}
@media only screen and (max-width: 360px) {
  .patient-second-column {
    width: 95%;
    padding: 10px;
  }
}
.box-shadow {
  box-shadow: 0px 4px 15.8px -1px rgba(0, 0, 0, 0.2);
}
.border-none {
  border: none !important;
}
.patient-phone-dob {
  font-family: "Fjord One", serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}
.patient-name-medical,
.patient-name-medical h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
}
.patient-name-medical h1 {
  font-weight: 400;
  margin-bottom: 0px;
}
.patient-name-medical p {
  font-weight: 300;
  margin-top: 7px;
}
