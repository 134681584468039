/* Center Container */
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110vh;
}
/* Signup Container */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
/* Signup box styling */

.signup-box {
  position: relative;
  z-index: 2;
  background: var(--blue-bg);
  padding: 30px 60px 60px 60px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 400px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}
/* Heading styling */
.signup-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--heading);
}
/* Form group styling */

.signup-form-group {
  margin-bottom: 15px;
  width: 100%;
}
.signnup-input {
  background-color: var(--input-bg);
}
.signnup-input:focus {
  background-color: var(--input-focus-bg);
  border: 2px solid var(--input-border);
}
/* Input field styling */

.signup-form-group input,
.signup-dropdown {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
}
/* Signup button styling */

.signup-btn {
  width: 100%;
  padding: 10px;
  background: var(--btn-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
}
/* Links container styling */

.links {
  margin-top: 15px;
}
/* Link styling */

.signup-link {
  color: var(--blue-text);
  text-decoration: none;
}
/* Account text styling */

.account-text,
.signup-dropdown {
  color: var(--input-text);
}
/* Paragraph in links styling */

.links p {
  margin: 10px 0 0;
}
/* Footer styles */
.signup-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.password-mismatch {
  color: red;
  font-size: 0.8em;
  margin-top: -10px;
  margin-bottom: 10px;
}
/* Media queries */

@media (max-width: 768px) {
  .signup-box {
    padding: 0px 20px 0px 20px;
    width: auto;
  }
}
@media (min-width: 350px) and (max-width: 768px) {
  .center-container {
    height: 118vh;
  }
}
@media (min-width: 769px) {
  .center-container {
    height: 135vh;
  }
}
