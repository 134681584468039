.line-input {
  color: var(--input-text);
  border: 1px solid var(--input-border);
  border-radius: 5px;
}
.user-profile-card.edit-user img {
  margin-bottom: 0px;
}
.edit-user .user-card-line {
  margin-bottom: 15px;
}
.edit-user .line-input {
  height: 40px;
  width: 100%;
}
.edit-user label {
  color: var(--label-text);
  font-size: 10px;
  margin-top: 5px;
  width: 100%;
}
.line-input-container {
  flex-wrap: wrap;
}
.edit-image-btn {
  margin-top: 10px;
  margin-bottom: 30px;
}
.user-profile-card.edit-user .edit-user {
  margin-bottom: 40px;
}
.error-message {
  color: rgb(180, 5, 5);
  padding: 10px;
}
