/* Container for centering content in the viewport */
.patient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Styles for the heading inside the patient container */
.patient-container h1 {
  margin: 0;
}

/* Main form styling */
.new-patient-form {
  position: relative;
  z-index: 2;
  background: var(--blue-bg);
  padding: 20px 60px 60px 60px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  margin-top: 19px;
  width: 100%;
  box-sizing: border-box;
}

/* Heading styles for the new patient form */
.new-patient-form h1 {
  margin-bottom: 20px;
  color: var(--heading);
}

/* Divider below the heading */
.border-bottom {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--heading);
  width: 30%;
}

/* Flex container for form rows */
.patient-form-row {
  display: flex;
  margin-bottom: 15px;
  white-space: nowrap;
}

/* Form group styling for inputs and labels */
.patient-form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Styles for form inputs and selects */
.patient-form-group input,
.patient-form-group select {
  width: 95%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
}

/* Input width adjustment for language field */
.language input {
  width: 98%;
}

/* Select width adjustment for billing field */
.billing select {
  width: 32%;
}

/* Input focus styles */
.patient-form-group input:focus {
  background-color: var(--input-focus-bg);
  border: 2px solid var(--input-border);
}

/* Label and section heading styles */
.patient-form-group label,
.insurance-section h4 {
  margin-bottom: 8px;
  font-weight: bold;
  color: #000;
}

/* Asterisk color for required fields */
.asterick {
  color: #ff0000;
}

/* Insurance section styling */
.insurance-section {
  flex-direction: column;
  white-space: nowrap;
}

/* Insurance details container */
.insurance-details {
  border: 1px solid #00000038;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  gap: 10px;
  width: 100%;
}

/* Bottom label style */
.bottom-label {
  color: var(--heading) !important;
  font-size: 12px;
}

/* Button styling for adding insurance details */
.insurance-add-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: var(--blue-bg);
  color: #3d7cd8;
  font-weight: bold;
  border: none;
}
.insurance-add-btn:hover {
  cursor: pointer;
}
/* Button styling for adding a patient */
.add-patient {
  background-color: var(--btn-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 20px;
  align-self: center;
}
.add-patient.secondary {
  background-color: var(--btn-gray);
  color: black;
}
/* Responsive styles for mobile screens */
@media only screen and (max-width: 768px) {
  /* Adjust form row and insurance details to column layout on small screens */
  .patient-form-row,
  .insurance-details {
    flex-direction: column;
  }

  /* Remove padding for patient form media on small screens */
  .patient-form-media {
    padding: 0 !important;
  }

  /* Reduce padding inside the form on small screens */
  .new-patient-form {
    padding: 20px;
  }

  /* Adjust input and select widths for small screens */
  .patient-form-group input,
  .billing select,
  .patient-form-group select {
    width: 100%;
  }

  /* Remove bottom margin for labels on small screens */
  #root
    > div.create-grid-container
    > div.second-column.patient-form-media
    > div:nth-child(2)
    > form
    > div:nth-child(3)
    > div:nth-child(1)
    > label.bottom-label {
    margin-bottom: 0;
  }

  /* Adjust spacing for specific fields on small screens */
  .patient-dr,
  #patient-tel,
  .date-of-service,
  .time-of-service {
    margin-bottom: 13px;
  }

  /* Center the add button for insurance details on small screens */
  .patient-form-group-btn {
    display: flex;
    justify-content: center;
  }

  /* Adjust heading size and prevent text wrapping on small screens */
  .new-patient-form h1 {
    font-size: 26px;
    white-space: nowrap;
  }
}
@media (min-width: 769px) and (max-width: 875px) {
  .insurance-details {
    flex-direction: column;
  }
}

.patient-form-group-btn.center {
  display: flex;
  align-items: center;
  padding-top: 25px;
}
@media (min-width: 769px) and (max-width: 920px) {
  .insurance-row {
    flex-direction: column;
  }
}
