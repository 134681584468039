/*  profile container */
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88vh;
}

/*  profile card */
.profile-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 600px;
  text-align: center;
}

/*  profile avatar container */
.profile-avatar {
  position: relative;
  margin-bottom: 20px;
}

/*  profile avatar image */
.profile-avatar img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

/*  edit button */
.edit-button {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  color: #598dde;
  cursor: pointer;
}

/*  profile form */
.profile-form {
  display: flex;
  flex-direction: column;
}

/*  form rows */
.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/*  profile form groups */
.profile-form-group {
  display: flex;
  flex-direction: column;
  width: 48%;
}

/*  labels in profile form groups */
.profile-form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #20365e;
}

/*  profile text and under text labels */
.profile-text-label,
.under-text-label {
  text-align: left;
}

/*  under text labels */
.under-text-label {
  color: #333;
  font-size: 12px;
  margin-top: 3px;
}

/*  profile form inputs and selects */
.profile-form input,
.profile-form select {
  padding: 10px;
  border: 2px solid #9f9fab;
  border-radius: 4px;
}

/*  form action buttons container */
.form-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*  save and logout buttons */
.save-button,
.logout-button {
  width: 30%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
}

/*  save button */
.save-button {
  background-color: #598dde;
  color: #ffffff;
  margin: 20px 0 10px 0;
  font-weight: bold;
}

/*  logout button */
.logout-button {
  background-color: #9f9fab;
  color: #333;
  font-weight: bold;
}
/* Hidden heading for Lighthousr Audit */
.visually-hidden {
  display: none;
}

@media (min-width: 350px) and (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
  .profile-card,
  .profile-form-group {
    width: auto;
  }
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125vh;
  }
  .signup-dropdown,
  #email,
  #update-password {
    margin-bottom: 18px;
  }
  #pin{
    margin-bottom:5px;
  }
  .profile-form-group label {
    margin-bottom: 0;
  }
}
