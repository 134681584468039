/*  sidenav container */

/*  sidenav */
.sidenav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*  sidenav header */
.sidenav-header {
  margin-bottom: 20px;
}

/*  sidenav logo */
.sidenav-logo {
  width: 180px;
  margin-bottom: 20px;
}

/*  sidenav menu */
.sidenav-menu {
  padding-top: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

/*  sidenav links */
.sidenav-link {
  width: auto;
  padding: 10px 20px;
  text-decoration: none;
  color: var(--nav-text);
  display: flex;
  transition: background-color 0.3s;
}

/* Hover effect for the sidenav links */
.sidenav-link:hover {
  background-color: #e0e0e0;
}

/*  icons in the sidenav */
.icon-dashboard,
.icon-add-patient,
.icon-search-patient {
  margin-right: 10px;
}

/*  button container */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

/*  buttons in the button container */
.button-container button {
  display: flex;
  justify-content: center;
  padding: 25px 50px;
  margin: 0 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  border: none;
  box-shadow: 0px 4px 15px -1px rgba(0, 0, 0, 0.1);
  color: var(--text);
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
}

/*  icons in the buttons */
.button-container button i {
  margin-right: 10px;
}

/* Hover effect for the buttons */
.button-container button:hover {
  background-color: #e0e0e0;
}
@media (max-width: 768px) {
  .button-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  .button-container button {
    margin-bottom: 10px;
  }
}
