/* Global Styles */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  height: 100vh;
  margin: 0;
  background-color: var(--bg-color);
  font-family: "Inter", sans-serif;
}

:root {
  /* Background (bg) colors */
  --nav-bg-color: #ffffff;
  --sidebar-bg-color: #ffffff;
  --bg-color: #f5f7fc66;

  /* General */
  --box-shadow: 0px 4px 15px -1px rgba(0, 0, 0, 0.1);
  --hr-color: #8382924d;

  /* Button background colors */
  --btn-blue: #5895ee;
  --btn-red: #e34234bf;
  --btn-gray: #f4f3f3;

  /* Text colors */
  --heading: #52596a;
  --text: #5b5b5b;
  --blue-text: #5895ee;

  /* Navbar colors */
  --nav-text: #4f4f4f;
  --nav-border-color: #8382924d;

  /* Form Input colors */
  --label-text: #52596a;
  --input-text: #646464;
  --input-border: #83829266;
  --input-bg: #ffffff;
  --input-focus-bg: #f8f9fa;

  /* Form Box colors */
  --red-bg: #f5c4c31a;
  --red-border: #f5c4c3;
  --green-bg: #b6dde11a;
  --green-border: #b6dde1;
  --blue-bg: #fbfdff;
  --blue-border: #c4d2ea;

  /* Patient Profile Page colors */
  --darker-blue: #0963e8;
  --bold-font: #727273;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}
