.password-reset-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pw-text {
  margin-bottom: 3rem;
  text-align: center;
}
.pw-container {
  background-color: var(--input-bg);
  width: 45%;
  margin: 20px auto;
  padding: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  border: 2px solid var(--hr-color);
}

.password-btn {
  margin: 3rem 0 2rem 0;
  padding: 10px 25px;
  background: var(--btn-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
}
.password-form {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  color: var(--text);
}
#pw-email {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
}

.password-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.email-label {
  align-self: flex-start;
  width: 100%;
}

.span {
  display: flex;
  align-items: baseline;
}
.signup {
  color: var(--btn-blue);
  text-decoration: none;
}
.message {
  color: rgb(246, 9, 9);
}
@media only screen and (max-width: 350px) {
  .span {
    display: block;
  }
  .signup-spacing {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .password-reset-page {
    margin-top: 15%;
  }
  .pw-container {
    width: auto;
  }
  .password-form {
    padding: 20px;
  }
}
