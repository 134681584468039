.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}
.thank-you-icon {
  font-size: 24px;
  color: var(--text);
  background-color: var(--btn-gray);
  padding: 8px;
  border-radius: 50%;
}
.thank-you-btn {
  background-color: #5895ee;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 40px;
    border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin-top: 70px;
}
.thank-you-text {
  color: var(--text);
}
.text-two {
  margin-top: 0;
}
