/* Container styling */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
  height: 100vh;
}

/* Background overlay */
.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/* Login box styling */
.login-box {
  position: relative;
  z-index: 2;
  background: var(--blue-bg);
  padding: 30px 60px 60px 60px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 400px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

/* Heading styling */
.login-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--heading);
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
  width: 100%;
}

/* Input field styling */
.login-input {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
}
.login-input:focus {
  background-color: var(--input-focus-bg);
  border: 2px solid var(--input-border);
}
/* Login button styling */
.login-btn {
  width: 100%;
  padding: 10px;
  background: var(--btn-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
}

/* Links container styling */
.links {
  margin-top: 15px;
}

/* Link styling */
.links a {
  color: var(--blue-text);
  text-decoration: none;
}

/* Account text styling */
.account-text {
  color: var(--text);
}

/* Paragraph in links styling */
.links p {
  margin: 10px 0 0;
}
/* Footer Styles */

.login-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

/* Media queries */

@media (max-width: 768px) {
  .login-box {
    padding: 0px 20px 23px 20px;
    width: auto;
  }
  .login-container {
    margin-top: 3%;
  }
}

.auth-background-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 85%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
