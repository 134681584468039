/* styles for all patient forms */
.back-arrow {
  margin: 10rem 1rem 0 3rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.form-title {
  text-align: center;
}
.arrow-container {
  display: flex;
  align-items: center;
}

.back-arrow .icon {
  margin-right: 0.5rem;
}

.form-content {
  margin: auto;
  margin-bottom: 200px;
  max-width: 900px;
  padding: 0 2%;
  width: 96%;
}

.form-content h2,
.form-content h3 {
  text-align: center;
}

.form-content li {
  margin-bottom: 15px;
  line-height: 1.4rem;
}

/* disclosure of benefits */
.center {
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
  margin-bottom: 3rem;
}
.bottom-border-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
  background-color: transparent;
  width: 100%;
}
/* grievance form */
.lh-center {
  padding-top: 10px;
}
/* grid for grievance */
.grievance-container {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  gap: 10px;
}
/* privacy policy */
.privacy-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  gap: 10px;
}

.privacy-item:nth-child(3) {
  grid-column: span 2;
}

.privacy-item {
  padding: 10px;
}
/* anethesia consent */
.anethesia-ul {
  list-style: none;
  padding: 0;
}

.anethesia-li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.anethesia-li input[type="text"] {
  margin-right: 10px;
  width: 50px;
  text-align: center;
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
  background-color: transparent;
  padding: 5px;
}

/* consent anethesia */
.ca-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
  outline: none;
}
/* patient satisfaction */
.satisfaction-container {
  margin: 20px;
  line-height: 1.5;
}

.pa-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.question-label {
  flex: 1;
}

.options {
  display: flex;
  gap: 20px;
}

.options input[type="radio"] {
  margin-left: 5px;
}

.comments-section {
  margin-top: 20px;
}

.comments-section textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}
/* anethesia questionaire */
.aq-inputs-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: 2px solid #000;
}

.aq-input-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid #000;
  flex: 1;
  padding: 5px;
}

.aq-input-item label {
  font-size: 14px;
  font-weight: bold;
}

.aq-input-item input {
  width: 80%;
  padding: 5px;
  border: none;
}
.aq-medical-form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.aq-form-section {
  width: 50%;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  padding: 5px;
  resize: none;
}

.short-input {
  width: 50px;
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px;
}

.long-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px;
}
/* have you had */

.aq-form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
.aq-form-list li {
  margin: 0;
}
.aq-form-options {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

.aq-option-label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.list-letters {
  padding-left: 3rem;
}
.checkbox {
  margin: 0;
}
.hyh-title {
  width: 60em;
}
.aq-pregnant {
  padding: 0;
}
/* discharge instructions */
.di-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, auto);
  gap: 10px;
}
.si-container {
  display: flex;
  align-items: center;
}
.si-text {
  white-space: nowrap;
}
.di-input {
  border: none;
  border-bottom: 1px solid var(--input-text);
}
.di-followup input,
.di-followup label {
  margin-right: 8px;
}
.di-followup {
  display: flex;
}
/* patient history */
.pi-container {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
  margin: 20px 0;
  white-space: nowrap;
}

.pi-form-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 12px;
}

.form-row:last-child {
  border-bottom: none;
}

.pi-form-row label {
  font-weight: bold;
}

.pi-form-row input {
  border: none;

  padding: 2px;
  flex-grow: 1;
}

.pi-short-input {
  width: 80px;
}

.pi-long-input {
  flex: 1;
}

.ph-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
}

.ph-grid-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ph-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.ph-long-input {
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
  margin-bottom: 10px;
}

.ph-checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ph-grid-item div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ph-grid-item div label {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-basis: 48%;
}
.ph-checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ph-checkbox {
  margin: 0;
}
.sa-form {
  margin: 20px 0;
}

.sa-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.sa-row span {
  margin-right: 10px;
}

.sa-checkbox {
  margin: 0 5px;
}

.sa-input-short {
  width: 10rem;
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 5px;
}

.sa-input-long {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid #000;
  margin-left: 5px;
}
.sa-text {
  padding-left: 5px;
}
.ph-container {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  gap: 10px;
}

.hp-form p {
  margin: 10px 0;
}

.hp-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.hp-row p {
  min-width: 150px;
  margin: 0;
}

.hp-bottom-border-input,
.employment-form input {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid #000;
  padding: 2px;
}

.hp-checkbox-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.hp-checkbox {
  margin-right: 10px;
}
/* patient registration */

.pr-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pr-form-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.pr-form-row label {
  margin: 0;
  white-space: nowrap;
}

.radio-group {
  display: flex;
  gap: 15px;
  align-items: center;
}

.pr-form-row input[type="radio"] {
  vertical-align: middle;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pr-input-line {
  flex: 1;
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
}

.pr-input-long-line {
  flex: 2;
  border: none;
  border-bottom: 1px solid #000;
  width: 100%;
}

input[type="radio"] {
  margin-right: 5px;
}

.pr-form-row:last-child {
  margin-bottom: 0;
}

.pr-container {
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
}

.pr-row {
  display: flex;
  width: 100%;
}

.pr-col-20 {
  width: 20%;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.pr-col-80 {
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
}

.employment-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.employment-row label {
  margin-right: 10px;
}

.employment-input {
  border: none;
  border-bottom: 1px solid #000;
  margin-right: 10px;
  flex: 1;
}

.employment-input-long {
  border: none;
  border-bottom: 1px solid #000;
  margin-right: 10px;
  flex-grow: 1;
}
.ef-text {
  margin-top: 0;
}
input[type="radio"] {
  margin-right: 5px;
}
.ie-row {
  align-items: center;
}
.ie-short-input {
  width: 15px !important;
}
.pr-row label {
  white-space: nowrap;
}
@media only screen and (min-width: 600px) {
  .grid-2 {
    align-items: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .di-followup input,
  .di-followup label {
    margin-bottom: 10px;
  }
  .satisfaction-container {
    margin: 10px;
  }
  .comments-section textarea {
    width: 94%;
  }
  .aq-form-section {
    width: 100%;
  }
  .aq-medical-form {
    flex-direction: column;
  }
  .aq-form-list {
    padding-left: 5px;
  }
  .aq-form-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .aq-form-item p {
    white-space: wrap;
  }
  .hyh-title {
    width: auto;
  }
  .pi-form-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .aq-form-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .long-input {
    width: 98%;
  }
  .ph-grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    width: 100%;
  }

  .ph-grid-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .ph-label {
    margin-bottom: 8px;
    font-weight: 600;
  }

  .ph-long-input {
    margin-bottom: 8px;
    width: 100%;
  }

  .ph-checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .ph-checkbox-label strong {
    margin-left: 5px;
  }
  .sig-mobile {
    display: flex;
    flex-direction: column;
  }
  .pr-form-row,
  .radio-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .pr-row {
    flex-direction: column;
  }
  .pr-col-20 {
    width: 100%;
    display: block;
    text-align: center;
  }
  .ie-row {
    align-items: flex-start;
  }
  .pr-col-80,
  .employment-input,
  .employment-input-long,
  .ie-short-input {
    width: 100% !important;
  }
  .employment-input {
    margin-bottom: 10px;
  }
  .employment-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .pr-row label {
    white-space: pre-wrap;
  }
}
.mt-50 {
  margin-top: 50px;
}
.name-input {
  width: 100%;
  padding: 20px 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
  font-size: 20px;
}
