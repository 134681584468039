/*  dashboard navigation container */
.admin-dashboard-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 14%;
}

/*  manage button container */
.admin-manage-button {
  display: flex;
  justify-content: center;
  width: 100%;
}
button {
  white-space: nowrap;
}
/*  manage button */
.admin-manage-btn {
  padding: 10px 20px;
  border: none;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

/*  button container */
.admin-button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

/*  manage, search, and add buttons */
.admin-manage-btn,
.admin-search-btn,
.admin-add-btn {
  padding: 25px 50px;
  margin: 0 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  border: none;
  box-shadow: 0px 4px 15px -1px rgba(0, 0, 0, 0.1);
  color: var(--text);
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
}
a {
  text-decoration: none;
  color: var(--text);
}
/* Hover effects for the buttons */
.admin-button-container button:hover,
.admin-manage-btn:hover {
  background-color: #e0e0e0;
}
@media (max-width: 768px) {
  .admin-button-container {
    flex-direction: column;
    width: auto;
  }
  .admin-dashboard-nav {
    height: 100vh;
    justify-content: center;
  }
  #root
    > div.nav-container
    > div.sidenav.open
    > div.admin-sidenav
    > div.sidenav-footer {
    display: none;
  }
}
