/*  navigation container */
.nav-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--nav-bg-color);
}

/*  navigation bar */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 100px;
  border-bottom: 0.5px solid #ddd;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  background-color: var(--nav-bg-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

/*  navigation logo */
.nav-logo {
  height: 100px;
  margin-left: 1.5rem;
}

/*  profile information section */
.profile-info {
  display: flex;
  align-items: center;
  padding-right: 20px;
  position: relative;
}

/*  profile text */
.profile-text {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

/*  profile image */
.profile-img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

/*  hamburger menu */
.hamburger-menu {
  display: none;
  cursor: pointer;
  padding: 10px;
}

/*  hamburger menu line */
.hamburger-div {
  width: 25px;
  height: 3px;
  background-color: var(--nav-text);
  margin: 5px 0;
  transition: 0.4s;
}

/*  side navigation */
.sidenav {
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: var(--nav-bg-color);
  z-index: 1001;
  box-shadow: var(--box-shadow);
  padding-top: 60px;
  overflow-y: auto;
  transition: visibility 0.3s, opacity 0.3s;
}

/*  open state of the side navigation */
.sidenav.open {
  visibility: visible;
  opacity: 1;
}

/*  desktop side navigation */
.sidenav-desktop {
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: var(--nav-bg-color);
  z-index: 1001;
  box-shadow: none;
  padding-top: 60px;
  overflow-y: auto;
  display: flex;
}

/*  side navigation links */
.sidenav-link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: var(--nav-text);
}

/*  close menu button */
.close-menu {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  cursor: pointer;
}

/*  overlay */
.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: visibility 0.3s, opacity 0.3s;
}

/*  open state of the overlay */
.overlay.open {
  visibility: visible;
  opacity: 1;
}

/*  dashboard navigation */
.dashboard-nav {
  width: 100%;
}

/*  side navigation container */
.sidenav-container {
  display: block;
}

/*  admin side navigation */
.admin-sidenav {
  position: fixed;
  left: 0;
  top: 100px;
  bottom: 0;
  width: 250px;
  overflow-y: auto;
  background-color: var(--nav-bg-color);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

/*  side navigation menu */
.sidenav-menu {
  flex-grow: 1;
}

/*  side navigation links */
.sidenav-link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: var(--nav-text);
  /* transition: background-color 0.3s; */
}

/* active state of side navigation links */
.sidenav-link.active-link {
  color: var(--blue-text);
}

/* hover state of side navigation links */
.sidenav-link:hover {
  background-color: var(--input-focus-bg);
}

/*  side navigation link icons */
.sidenav-link svg {
  margin-right: 10px;
}

/*  side navigation footer */
.sidenav-footer {
  padding: 20px;
  text-align: center;
}

/*  images in the side navigation footer */
.sidenav-footer img {
  max-width: 100%;
  height: 30px;
}

.name-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.caret {
  margin: 5px;
  margin-right: 20px;
  margin-left: 0;
  color: var(--btn-blue);
  font-size: 20px;
}
.flip {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  min-width: 150px;
}

.logout-btn {
  width: 56%;
  padding: 10px;
  background: var(--btn-blue);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  font-weight: bold;
}
/*
 PATIENT NAV 
 */

.patient-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100px;
  border-bottom: 0.5px solid #ddd;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  background-color: var(--nav-bg-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.patient-nav-logo {
  height: 100px;
}
/* Media queries for screens with max-width of 768px */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }
  .profile-text {
    display: none;
  }
  .sidenav-desktop {
    display: none;
  }
  .admin-sidenav {
    box-shadow: none;
    position: relative;
    top: 0;
  }
  .sidenav-menu {
    display: flex;
    align-items: center !important;
  }
  .sidenav-footer {
    bottom: 60px;
    position: absolute;
  }
  .caret {
    display: none;
  }
  .login-signup-nav .nav-logo {
    margin-left: 0;
  }

  .login-signup-nav .nav {
    display: flex;
    justify-content: center;
  }
}

/* Media queries for screens with min-width of 769px */
@media screen and (min-width: 769px) {
  .admin-sidenav-container {
    display: block;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    width: 250px;
    overflow-y: auto;
  }
  .hamburger-menu {
    display: none;
  }
  .sidenav {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}
