.sign-btn {
  background-color: #e6e4e1;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}
.sign-btn:hover {
  cursor: pointer;
}
.sign-btn p {
  font-weight: bold;
  margin: 0;
  margin-top: 5px;
}
.sign-btn img {
  width: 30px;
}
