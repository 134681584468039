/* Form container styles */
.create-grid-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-bg);
  box-shadow: var(--box-shadow);
  border-left: 1px solid var(--input-border);
  border-right: 1px solid var(--input-border);
  border-top: 1px solid var(--input-border);
  border-radius: 5px;
  padding: 20px;
}

/* Grid position styles */
.grid-position {
  display: flex;
  justify-content: center;
  padding-top: 3%;
}

/* Heading styles */
.create-heading {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--input-border);
  width: 100%;
}

/* Grid container styles */
.create-grid-container {
  width: 100%;
  color: var(--text);
  display: grid;
  grid-template-columns: 250px calc(100% - 250px);
}

/* Grid item styles */
.create-grid-item {
  padding: 10px;
  text-align: left;
}

/* Grid item label styles */
.create-grid-item-label {
  text-align: left;
  align-self: center;
}

/* Input styles */
.create-input {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-bg);
}

/* Button styles */
.create-user-button {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--blue-text);
  color: var(--btn-gray);
  margin-bottom: 15px;
}
/* .second-column{
  background-image: url("../../../assets/images/background_logo.webp");
  background-size: 70%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
} */

@media (max-width: 768px) {
  .create-grid-box {
    width: 100%;
  }

  .create-grid-container {
    grid-template-columns: 1fr 100fr;
  }
}
@media (max-width: 550px) {
  .search-row {
    flex-direction: column;
  }
  .admin-search-dropdown,
  .admin-search-input {
    width: 100%;
  }
  .admin-user-search-btn {
    display: none;
  }
  .admin-search-dropdown {
    margin-bottom: 8px;
  }
}

.password-error {
  color: rgb(197, 22, 22);
  margin: 0;
  margin-top: 5px;
}
