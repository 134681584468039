/*  entire card container */
.admin-card-container-approval {
  background-color: white;
  padding-top: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  width: 400px;
  margin-top: 30px;
  color: var(--text);
  font-family: "Inter", sans-serif;
  border: var(--hr-color) solid 1px;
}

/* profile information container */
.profile-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/*  profile image container */
.profile-image {
  flex-shrink: 0;
  margin: 0px 20px;
}

/*  profile image */
.user-img-approval {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

/*  for profile text information */
.profile-info-text-approval {
  flex-grow: 1;
}

/*  card heading */
.card-heading-approval {
  margin: 0 0 5px 0;
  font-size: 1.2em;
}

/*  for individual info items in profile */
.info-item-approval {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  margin: 0 0 5px 0;
}

/*  for paragraph within info items */
.info-item-approval p {
  margin: 0;
}

/*  for button container */
.approval-btn-container {
  display: flex;
}

/*  for buttons within button container */
.approval-btn-container button {
  flex: 1;
  padding: 10px 0;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
}

/*  for delete button */
.delete-btn {
  background-color: #f3f3f3;
  color: black;
  border-radius: 0 0 0 5px;
}

/*  for approve button */
.approve-btn {
  background-color: var(--btn-blue);
  color: white;
  border-radius: 0 0 5px 0;
}

/* Hover effects for buttons */
.delete-btn:hover,
.approve-btn:hover {
  opacity: 0.8;
}
.approval-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .admin-search-tabs.active::after {
    background-color: transparent;
  }
  .admin-card-container-approval {
    width: auto;
  }
  .info-item-approval {
    font-size: 12px;
  }
  .approval-btn-container {
    display: flex;
    flex-direction: column;
  }
  .profile-info-container {
    padding-right: 20px;
  }
  .approve-btn {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .delete-btn {
    border-radius: 0;
  }
}
@media (max-width: 525px) {
  .second-column-item-links {
    position: relative;
  }

  .menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--blue-bg);
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .hamburger-menu span {
    width: 2rem;
    height: 0.25rem;
    background: var(--darker-blue);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .active-tab-name {
    font-size: 13px;
    font-weight: bold;
    padding-left: 10px;
    color: var(--darker-blue);
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    background: var(--blue-bg);
    height: calc(49vh - 100px);
    width: 80%;
    text-align: left;
    padding: 0 2rem;
    position: absolute;
    top: 106%;
    left: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-200%);
    z-index: 1000;
    box-shadow: var(--box-shadow);
    border: 1px solid #bbb;
    border-radius: 5px;
  }

  .menu-items.open {
    transform: translateX(0);
  }

  .admin-search-tabs {
    font-size: 13px;
    padding: 1rem 0 0 0;
    font-weight: bold;
    color: var(--darker-blue);
    text-decoration: none;
    transition: color 0.3s linear;
  }
}

@media (min-width: 526px) {
  .menu-header {
    display: none;
  }

  .menu-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
