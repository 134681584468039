/*  grid container */
.grid-container {
  display: block;
}

/*  second column */
.second-column {
  padding: 20px;
  margin-top: 100px;
}

/*  heading and links in the second column */
.second-column-item-heading,
.second-column-item-links {
  background-color: var(--blue-bg);
  padding: 10px;
  border: 1px solid #bbb;
}

/*  heading in the second column */
.second-column-item-heading {
  margin-top: 20px;
  padding-left: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/*  links in the second column */
.second-column-item-links {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*  search heading */
.search-heading {
  color: var(--heading);
}

/*  link container in the second column */
.second-column-item-links {
  display: flex;
  align-items: center;
}

/*  search tabs */
.admin-search-tabs {
  position: relative;
  text-decoration: none;
  color: var(--darker-blue);
  cursor: pointer;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

/* Hover and focus  search tabs */
.admin-search-tabs:hover,
.admin-search-tabs:focus {
  background-color: var(--light-blue-bg);
}

/* Active state  search tabs */
.admin-search-tabs.active {
  color: var(--darker-blue);
  font-weight: bold;
}

/*  dot under the active tab */
.admin-search-tabs::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: transparent;
}

/* Active state  dot under the active tab */
.admin-search-tabs.active::after {
  background-color: var(--darker-blue);
}

/*  search dropdown and input */
.admin-search-dropdown,
.admin-search-input {
  background-color: var(--input-bg);
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--text);
}

/*  search input */
.admin-search-input {
  width: calc(100%);
}

/* Common  dropdown, input, and search button */
.admin-search-dropdown,
.admin-search-input,
.admin-user-search-btn {
  font-size: 15px;
}

/*  search button */
.admin-user-search-btn {
  width: 120px;
  padding: 10px;
  background-color: var(--btn-blue);
  color: #fff;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  box-shadow: 0 4px 6px var(--box-shadow);
  font-weight: bold;
  transition: box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-user-search-btn:hover {
  cursor: pointer;
}

/*  search dropdown */
.admin-search-dropdown {
  width: 200px;
}
@media (max-width: 1000px) {
  .admin-search-dropdown {
    width: 150px;
  }
}

/*  search container */
.second-column-item-search {
  display: flex;
  align-items: center;
  padding: 45px 0;
}

/*  search row */
.search-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Focus  search input */
.admin-search-input:focus {
  background-color: var(--input-focus-bg);
  border: 2px solid var(--input-border);
  color: var(--input-text);
}

/*  card container */
.second-column-item-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px 0;
}

/*  individual cards */
.admin-card-container {
  display: flex;
  background-color: var(--blue-bg);
  color: var(--text);
  border-radius: 5px;
  border: 1px solid var(--input-border);
  box-shadow: var(--box-shadow);
  width: 95%;
  padding: 10px;
}

/*  profile image container */
.search-profile-image {
  align-items: center;
  display: flex;
  margin-right: 10px;
}

/*  profile image */
.user-img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

/*  profile text */
.profile-info-text {
  line-height: 1.2;
}

/*  individual info items */
.info-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 768px) {
  .search-heading {
    font-size: 17px;
  }
  .second-column-item-links {
    white-space: nowrap;
    /* font-size: 8px; */
  }
  .admin-card-container {
    width: 100%;
    padding: 0 0 9px 0;
  }
  .info-item {
    font-size: 12px;
  }
  .second-column-item-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-info-text {
    line-height: 0.2;
  }
  .card-heading {
    margin-bottom: 13px;
  }
}

.icon-search-user {
  margin-left: 5px;
}

.user-search-input-container {
  width: calc(100% - 350px);
}
@media (max-width: 1000px) {
  .user-search-input-container {
    width: calc(100% - 300px);
  }
}

.mobile-search-btn {
  display: none;
}

@media (max-width: 550px) {
  .user-search-input-container {
    position: relative;
    width: 100%;
  }

  .mobile-search-btn {
    background-color: transparent;
    border: none;
    color: var(--input-text);
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .mobile-search-btn:hover {
    cursor: pointer;
  }
}

.admin-card-container-approval.user-search-cards {
  position: relative;
}

.user-search-edit-icons {
  display: none;
  border: 1px solid var(--blue-text);
  border-radius: 50px;
  height: 30px;
  width: 30px;
  color: var(--darker-blue);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.admin-card-container-approval.user-search-cards:hover {
  cursor: pointer;
}

.admin-card-container-approval.user-search-cards:hover .user-search-edit-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .admin-card-container-approval.user-search-cards {
    min-width: 350px;
  }
}
