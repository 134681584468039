.col-2-md,
.col-2-sm {
  display: grid;
  gap: 20px;
}
.col-2-md {
  grid-template-columns: 1fr;
}
.col-2-sm {
  grid-template-columns: 1fr 1fr;
}

@media only screen and (min-width: 756px) {
  .col-5-lg {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  .col-4-lg {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 900px) and (min-width: 400px) {
  .col-2-md {
    grid-template-columns: 1fr 1fr;
  }
}

.btn-primary {
  background-color: var(--btn-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  align-self: center;
}
.btn-primary a {
  color: white;
}

.btn-grey {
  background-color: var(--btn-gray);
  color: gray;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  align-self: center;
}
.btn-ready {
  background-color: #e3423474;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  align-self: center;
}
.surgery-card p {
  font-family: "Fjord One", serif;
  font-weight: 400;
  font-style: normal;
}
.forms-menu {
  background-color: var(--blue-bg);
  border-bottom: 1px solid #83829259;
  padding: 15px;
  padding-bottom: 0px;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px;
  width: auto;
  height: 45px;
  display: flex;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .forms-menu {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    font-size: 13px;
  }
}
.forms-menu .patient-tabs {
  margin-bottom: 0px;
}
.forms-menu .current {
  font-weight: bold;
  border-bottom: 3px solid #5895ee;
  border-radius: 0;
}
/* Form boxes */
.form-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid#D3DDEF;
  border-radius: 8px;
  box-shadow: 0px 4px 15px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 8px;
}
.form-box-label {
  font-size: 16px;
  font-weight: 500;
  color: var(--text);
}
.form-box-status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.form-box-status {
  font-size: 14px;
  color: var(--text);
}
.form-box-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.form-box-row a {
  width: calc(100% - 110px);
}
.form-box-row button {
  background-color: white;
  color: #5895ee75;
  border: 1px solid #5895ee75;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 0px;
  align-self: center;
  width: 50px;
  margin-bottom: 8px;
  transition: all 0.35s ease-in-out 0s;
}
.form-box-row button:hover {
  background-color: var(--btn-blue);
  color: white;
  border: 1px solid white;
}
