.form-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.patient-forms-view {
  display: block;
}
.consent-card {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid#D3DDEF;
  border-radius: 8px;
  box-shadow: 0px 4px 15px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 8px;
}

.consent-card-label {
  font-size: 16px;
  font-weight: 500;
  color: var(--text);
}

.consent-card-status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.consent-card-status {
  font-size: 14px;
  color: var(--text);
}

.patient-view-icon {
  font-size: 24px;
  color: var(--text);
  background-color: #fbfdff;
  border-radius: 50%;
  padding: 9px;
  border: 1px solid var(--text);
}
.patient-views-btn-container {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.patient-forms-btn {
  background-color: #5895ee;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
}
