.background-logo {
  display: flex;
  align-items: center;
  position: fixed;
  height: calc(100vh - 150px);
  width: calc(100vw - 290px);
  z-index: -1;
}
.background-image {
  height: auto;
  opacity: 0.2;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .background-logo {
    width: calc(100vw - 50px);
  }
}
