/* surgery form*/
.surgery-form-container {
  display: flex;
  justify-content: center;
}

/*  surgery form */
.surgery-form {
  background-color: var(--blue-bg);
  padding: 5px 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 350px;
  width: 100%;
  box-sizing: border-box;
}

/* Removes the top margin from the surgery info section */
div.surgery-info {
  margin-top: 0;
}

/* form heading */
.surgery-form h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--heading);
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* form group  */
.surgery-form-group {
  margin-bottom: 15px;
  padding: 0 30px;
}

/* form labels */
.surgery-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}

/* form inputs */
.surgery-form-group input {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  background-color: var(--input-bg);
}

/*  submit button */
.submit-button {
  width: 100%;
  padding: 10px;
  background-color: var(--btn-blue);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
  margin: 10px 0;
}
