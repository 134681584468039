/* Container styling for the entire patient info form */
.patient-info-form {
  position: relative;
  z-index: 2;
  background: var(--blue-bg);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  margin-top: 19px;
  width: 100%;
  box-sizing: border-box;
}

/* Bold labels for the form */
.patient-info-form label,
.table-label,
.billing-type-container h3 {
  font-weight: bold;
  color: #000;
}

/* Card and table container styling */
.patient-info-card {
  padding: 0 20px;
  margin-top: 0;
}

/* Table container styling */
.medications-table-container,
.insurance-table-container,
.surgeries-table-container,
.medical-conditions-container {
  margin: 20px;
  padding: 16px;
  overflow: auto;
}

/* Table styling */
.medications-table,
.insurance-table,
.surgeries-table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #bbb;
}

/* No-wrap for text content */
.nowrap {
  white-space: nowrap;
}

/* Cell styling for all tables */
.medications-table th,
.medications-table td,
.insurance-table th,
.insurance-table td,
.surgeries-table td {
  padding: 12px;
  /* border: 1px solid #e2e8f0; */
  text-align: left;
  border-right-color: transparent;
}

/* Edit and delete container styling */
.edit-trash-container {
  display: flex;
  justify-content: space-around;
}

/* Remove borders from specific cells */
tr td.edit-trash-container {
  border-left: none;
  border-top: none;
}

/* Status badge styling */
.status-badge {
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}

.status-badge.active {
  background-color: #598dde;
  color: white;
}

.status-badge.inactive {
  background-color: #a0aec0;
  color: white;
}

/* Button styling for edit, delete, and add actions */
.medication-edit-btn,
.medication-delete-btn,
.surgery-edit-btn,
.surgery-delete-btn,
.medication-add-btn,
.surgery-add-btn {
  border: none;
  background: none;
  cursor: pointer;
}

.medication-add-btn,
.surgery-add-btn {
  margin-left: 8px;
  padding: 6px 12px;
  color: #000;
  background-color: transparent;
}

/* Input field styling inside forms */
.patient-info-form input[type="text"],
.condition-field select,
.conditional-input,
.billing-type-container select,
.nowrap select {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}
/* bottom border  */
.medications-table thead tr,
.medications-table tbody tr,
.medications-table tbody tr:last-child {
  border-bottom: 1px solid #e2e8f0;
}

/* headings and layout  */
.allergy-heading {
  display: flex;
  align-items: center;
}

.billing-type-container label {
  margin-bottom: 8px !important;
}

/* condition fields in grid layout */
.medical-conditions-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.condition-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.condition-field label {
  margin-bottom: 8px;
}
.billing-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 20px;
  padding: 0 16px;
}

.billing-type-container {
  margin: 0 20px;
  padding: 0 16px;
}

/* Billing type container  */

.edit-section {
  display: flex;
  align-items: center;
}

.edit-section-btn {
  margin-left: auto;
}
/* Edit section styling */
.edit-section {
  grid-column: span 4;
  text-align: right;
}

.edit-section-btn {
  background: none;
  border: none;
  color: #3182ce;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.edit-section-btn svg {
  margin-left: 8px;
}

/* Payer button styling */
.payer-btn {
  background-color: #f3f3f3;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 20px;
  align-self: center;
}

.payer-btn-container {
  margin: 0 20px;
  padding: 0 16px;
}

/* Center align text in surgery actions */
td.surgery-actions {
  text-align: center;
}
/* Insurance styles */
.ins-row {
  padding: 1rem;
  gap: 10px;
}
.ins-row input {
  padding: 10px;
}
.insurance-table-billing {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-inputs-billing {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 12px;
}

.patient-form-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.333% - 10px);
  min-width: 150px;
  white-space: nowrap;
  font-size: 15px;
}

.add-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100%;
}

.medication-add-btn {
  text-align: center;
  padding: 8px 16px;
}

@media (min-width: 350px) and (max-width: 768px) {
  .patient-info-card {
    padding: 10px;
  }
  .patient-info-form {
    margin-top: 10px;
  }
}

/* Mobile view */
@media only screen and (max-width: 768px) {
  .billing-type-container {
    margin: 0;
    padding: 0;
  }
  .payer-btn-container {
    display: flex;
    justify-content: center;
  }
  .medication-add-btn {
    padding-top: 18px;
  }
  .patient-info-form {
    padding: 10px;
  }
  .medication-add-btn {
    padding-top: 18px;
  }
  .mobile-inputs select {
    width: 100%;
  }
  .medical-conditions-container {
    display: flex;
    flex-direction: column;
  }
  .payer-btn {
    margin-top: 0;
  }
  .payer-btn-container {
    padding: 20px;
  }
}
.mobile-heading,
.mobile-body {
  display: flex;
  justify-content: space-between;
}
.mobile-heading {
  align-items: center;
}
.mobile-inputs {
  display: flex;
  flex-direction: column;
}
.mobile-heading h1 {
  font-size: 22px;
}
.mobile-border {
  border: 2px solid #bbb;
  padding: 10px;
}

.mobile-icons,
.mobile-surgery {
  align-content: center;
}
.mobile-inputs label {
  margin: 8px;
}
@media screen and (min-width: 769px) and (max-width: 825px) {
  .patient-info-form {
    width: auto;
    /* margin: 0em 1em 0em 17em; */
  }
  .mobile-container {
    padding: 10px;
  }
  .medical-conditions-container {
    display: flex;
    flex-direction: column;
  }
  .billing-type-container {
    margin: 0;
    padding: 0;
  }
  #root
    > div.patient-info-form
    > div:nth-child(3)
    > div.medical-conditions-container
    > div.billing-type-container
    > select {
    margin-top: 8px;
  }
  .prior-surgeries {
    margin-top: 20px;
  }

  .payer-btn-container {
    display: flex;
    justify-content: center;
  }
  .payer-btn {
    margin-top: 0;
  }
  .medication-add-btn {
    padding-top: 18px;
  }
}
.update {
  border: none;
  border-radius: 5px;
  background-color: #598dde;
  color: white;
  font-weight: bold;
}
.disabled {
  pointer-events: none;
  background-color: #598cde79;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.medication-close-btn {
  background-color: transparent;
  border: none;
  color: #598dde;
}
.medication-close-btn:hover {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .w-100 {
    width: 100px;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medications-col-width {
    width: 45%;
  }
  .pharm-col-width {
    width: 30%;
  }
  .surg-col-width {
    width: 90%;
  }
}

.medications-table.dimmed,
.mobile-inputs.dimmed {
  opacity: 0.7;
  pointer-events: none;
}
.billing-header {
  display: flex;
  justify-content: start;
  align-items: center;
}
.edit-section-btn {
  margin-left: 20px;
}
.read-only {
  background-color: transparent !important;
  border: 1px solid #83829229 !important;
  pointer-events: none;
}
