/* Drawing Pad Styles */
.drawing-modal {
  background-color: rgba(0, 0, 0, 0.194);
  /* Cover the background */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  /* Position modal box */
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawing-modal-container {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}
.drawing-container {
  background-color: #f6f8fc;
  height: 200px;
  width: 700px;
  margin: auto;

  display: flex;
  overflow: hidden;
  color: white;
}
.hidden {
  display: none;
}
.drawing-header {
  background-color: #5895ee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 10px 0;
}
#toolbar {
  display: flex;
  justify-content: space-between;
}
.drawing-header button,
#toolbar button {
  background-color: transparent;
  border: none;
  color: #5895ee;
  font-weight: bold;
  padding: 10px 20px;
}
.drawing-header button {
  color: #fff;
  font-size: 25px;
}
.drawing-header button:hover,
#toolbar button:hover {
  cursor: pointer;
}
