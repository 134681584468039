.manage-users-breadcrumb {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  margin-top: 20px;
  padding: 2px 20px;
}
.manage-users-breadcrumb p,
.manage-users-breadcrumb a {
  margin-left: 10px;
  margin-right: 10px;
}
/* Style to View and Edit User Cards on Admin Page */
.user-profile-card {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  width: 300px;
}
.user-profile-card div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.user-profile-card img {
  border-radius: 50%;
  margin-top: 30px;
  margin-bottom: 20px;
  height: 150px;
  width: 150px;
}
div.user-card-line {
  justify-content: left;
  padding: 0 20px;
  max-width: 260px;
}
.line-heading {
  font-weight: bold;
  width: 70px;
}
.user-profile-card .edit-user {
  background-color: var(--btn-blue);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 10px 25px;
  width: 150px;
}
.user-profile-card .block-user {
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  color: var(--text);
  font-weight: bold;
  margin-bottom: 35px;
  padding: 10px 25px;
  width: 150px;
}
.user-profile-card .edit-user:hover,
.user-profile-card .block-user:hover {
  cursor: pointer;
}
.line-info {
  max-width: calc(100% - 80px);
  overflow-wrap: break-word;
  word-wrap: break-word;
}
