/* Grid container for the patient search layout */
.patient-search-grid-container {
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 20px;
}
/* Main search column, including search input and cards */
.patient-search-column-two {
  margin: 9rem 3rem;
}
/* Container for search input and button */
.patient-search-input-group {
  gap: 10px;
  display: flex;
}
/*  heading  */
.patient-search-column-two h1 {
  color: var(--heading);
}
/*search input */
.patient-search-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 76%;
}
/*search btn */
.patient-search-btn {
  background-color: var(--btn-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  align-items: center;
  display: flex;
}
/*  search icon inside the button */
.patient-search-icon {
  font-size: 20px;
  padding-left: 5px;
}
/* patient search cards */
.patient-search-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  width: auto;
  color: var(--text);
  font-family: "Inter", sans-serif;
  border: var(--hr-color) solid 1px;
}
.patient-search-name {
  margin-bottom: 0;
}

.profile-image {
  justify-self: start;
  display: flex;
  align-items: center;
}

.search-profile-image img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 20px;
}

.mrn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.mrn-dob {
  font-size: 14px;
  color: var(--text);
}

.mrn-value {
  font-size: 14px;
  color: var(--text);
}

.patient-search-card-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 2rem;
}
.patient-search-divider {
  border-bottom: var(--hr-color) solid 1px;
}
.patient-search-proc-dos {
  padding: 20px;
}
.procedure {
  padding-bottom: 10px;
}
.missing-sig-btn {
  background-color: var(--btn-red);
  color: white;
  border: 1px solid var(--btn-red);
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 12px;
  align-self: center;
}
.missing-sig-btn.hidden {
  display: none;
}
/* Media queries */
@media screen and (max-width: 768px) {
  .patient-search-grid-container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .patient-search-column-two {
    margin: 6rem 0;
  }

  .patient-search-input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .patient-search-input-group select {
    width: 100%;
  }
  .patient-search-input {
    width: 100%;
    padding: 10px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .patient-search-card-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 10px;
  }

  .patient-search-card {
    width: 100%;
  }
  .patient-search-btn {
    width: auto;
    align-self: center;
  }
  .mrn {
    flex-direction: column;
    gap: 0;
  }

  .mrn-value {
    margin-top: 4px;
    font-size: 12px;
  }
  #root
    > div.patient-search-grid-container
    > div.patient-search-column-two
    > div.patient-search-card-container
    > a:nth-child(1)
    > div
    > div.search-profile-image
    > div
    > div
    > p:nth-child(1) {
    margin-bottom: 0;
  }
  .mrn p {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 769px) and (max-width: 825px) {
  .patient-search-column-two {
    margin: 7rem 16rem;
  }
  .patient-search-input {
    width: 80%;
  }
  .patient-search-card-container {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 825px) and (max-width: 1440px) {
  .patient-search-column-two {
    margin: 9rem 17rem;
  }
}
@media screen and (min-width: 825px) and (max-width: 1222px) {
  .patient-search-card-container {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1440px) {
  .patient-search-column-two {
    margin: 9rem;
  }
}
